const config: any = {
    // Uncomment below to configure analytics.
    // googleAnalytics: {
    //    trackingId: 'UA-24123123-01',
    // },
    // mixpanel: {
    //    token: 'fad1285da4e618b618973cacf6565e61',
    // },
    // amplitude: {
    //    apiKey: 'c5c212632315d19c752ab083bc7c92ff',
    // },
    // logging: true,
    datahub: {
        enabled: true,
    },
};

export default config;
