/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetBrowseResultsV2QueryVariables = Types.Exact<{
    input: Types.BrowseV2Input;
}>;

export type GetBrowseResultsV2Query = { __typename?: 'Query' } & {
    browseV2?: Types.Maybe<
        { __typename?: 'BrowseResultsV2' } & Pick<Types.BrowseResultsV2, 'start' | 'count' | 'total'> & {
                groups: Array<
                    { __typename?: 'BrowseResultGroupV2' } & Pick<
                        Types.BrowseResultGroupV2,
                        'name' | 'count' | 'hasSubGroups'
                    > & {
                            entity?: Types.Maybe<
                                | ({ __typename?: 'AccessTokenMetadata' } & Pick<
                                      Types.AccessTokenMetadata,
                                      'urn' | 'type'
                                  >)
                                | ({ __typename?: 'Assertion' } & Pick<Types.Assertion, 'urn' | 'type'>)
                                | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                | ({ __typename?: 'Container' } & Pick<Types.Container, 'urn' | 'type'> & {
                                          properties?: Types.Maybe<
                                              { __typename?: 'ContainerProperties' } & Pick<
                                                  Types.ContainerProperties,
                                                  'name'
                                              >
                                          >;
                                      })
                                | ({ __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'urn' | 'type'>)
                                | ({ __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'type'>)
                                | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                | ({ __typename?: 'DataFlow' } & Pick<Types.DataFlow, 'urn' | 'type'> & {
                                          properties?: Types.Maybe<
                                              { __typename?: 'DataFlowProperties' } & Pick<
                                                  Types.DataFlowProperties,
                                                  'name'
                                              >
                                          >;
                                      })
                                | ({ __typename?: 'DataHubPolicy' } & Pick<Types.DataHubPolicy, 'urn' | 'type'>)
                                | ({ __typename?: 'DataHubRole' } & Pick<Types.DataHubRole, 'urn' | 'type'>)
                                | ({ __typename?: 'DataHubView' } & Pick<Types.DataHubView, 'urn' | 'type'>)
                                | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                                | ({ __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'urn' | 'type'>)
                                | ({ __typename?: 'DataPlatformInstance' } & Pick<
                                      Types.DataPlatformInstance,
                                      'instanceId' | 'urn' | 'type'
                                  > & {
                                          platform: { __typename?: 'DataPlatform' } & Pick<
                                              Types.DataPlatform,
                                              'name'
                                          > & {
                                                  properties?: Types.Maybe<
                                                      { __typename?: 'DataPlatformProperties' } & Pick<
                                                          Types.DataPlatformProperties,
                                                          'displayName'
                                                      >
                                                  >;
                                              };
                                      })
                                | ({ __typename?: 'DataProcessInstance' } & Pick<
                                      Types.DataProcessInstance,
                                      'urn' | 'type'
                                  >)
                                | ({ __typename?: 'DataProduct' } & Pick<Types.DataProduct, 'urn' | 'type'>)
                                | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                                | ({ __typename?: 'Domain' } & Pick<Types.Domain, 'urn' | 'type'>)
                                | ({ __typename?: 'GlossaryNode' } & Pick<Types.GlossaryNode, 'urn' | 'type'>)
                                | ({ __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'urn' | 'type'>)
                                | ({ __typename?: 'MLFeature' } & Pick<Types.MlFeature, 'urn' | 'type'>)
                                | ({ __typename?: 'MLFeatureTable' } & Pick<Types.MlFeatureTable, 'urn' | 'type'>)
                                | ({ __typename?: 'MLModel' } & Pick<Types.MlModel, 'urn' | 'type'>)
                                | ({ __typename?: 'MLModelGroup' } & Pick<Types.MlModelGroup, 'urn' | 'type'>)
                                | ({ __typename?: 'MLPrimaryKey' } & Pick<Types.MlPrimaryKey, 'urn' | 'type'>)
                                | ({ __typename?: 'Notebook' } & Pick<Types.Notebook, 'urn' | 'type'>)
                                | ({ __typename?: 'OwnershipTypeEntity' } & Pick<
                                      Types.OwnershipTypeEntity,
                                      'urn' | 'type'
                                  >)
                                | ({ __typename?: 'Post' } & Pick<Types.Post, 'urn' | 'type'>)
                                | ({ __typename?: 'QueryEntity' } & Pick<Types.QueryEntity, 'urn' | 'type'>)
                                | ({ __typename?: 'Role' } & Pick<Types.Role, 'urn' | 'type'>)
                                | ({ __typename?: 'SchemaFieldEntity' } & Pick<Types.SchemaFieldEntity, 'urn' | 'type'>)
                                | ({ __typename?: 'Tag' } & Pick<Types.Tag, 'urn' | 'type'>)
                                | ({ __typename?: 'Test' } & Pick<Types.Test, 'urn' | 'type'>)
                                | ({ __typename?: 'VersionedDataset' } & Pick<Types.VersionedDataset, 'urn' | 'type'>)
                            >;
                        }
                >;
                metadata: { __typename?: 'BrowseResultMetadata' } & Pick<
                    Types.BrowseResultMetadata,
                    'path' | 'totalNumEntities'
                >;
            }
    >;
};

export const GetBrowseResultsV2Document = gql`
    query getBrowseResultsV2($input: BrowseV2Input!) {
        browseV2(input: $input) {
            groups {
                name
                count
                hasSubGroups
                entity {
                    urn
                    type
                    ... on Container {
                        properties {
                            name
                        }
                    }
                    ... on DataFlow {
                        properties {
                            name
                        }
                    }
                    ... on DataPlatformInstance {
                        platform {
                            name
                            properties {
                                displayName
                            }
                        }
                        instanceId
                    }
                }
            }
            start
            count
            total
            metadata {
                path
                totalNumEntities
            }
        }
    }
`;

/**
 * __useGetBrowseResultsV2Query__
 *
 * To run a query within a React component, call `useGetBrowseResultsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetBrowseResultsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrowseResultsV2Query({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBrowseResultsV2Query(
    baseOptions: Apollo.QueryHookOptions<GetBrowseResultsV2Query, GetBrowseResultsV2QueryVariables>,
) {
    return Apollo.useQuery<GetBrowseResultsV2Query, GetBrowseResultsV2QueryVariables>(
        GetBrowseResultsV2Document,
        baseOptions,
    );
}
export function useGetBrowseResultsV2LazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetBrowseResultsV2Query, GetBrowseResultsV2QueryVariables>,
) {
    return Apollo.useLazyQuery<GetBrowseResultsV2Query, GetBrowseResultsV2QueryVariables>(
        GetBrowseResultsV2Document,
        baseOptions,
    );
}
export type GetBrowseResultsV2QueryHookResult = ReturnType<typeof useGetBrowseResultsV2Query>;
export type GetBrowseResultsV2LazyQueryHookResult = ReturnType<typeof useGetBrowseResultsV2LazyQuery>;
export type GetBrowseResultsV2QueryResult = Apollo.QueryResult<
    GetBrowseResultsV2Query,
    GetBrowseResultsV2QueryVariables
>;
